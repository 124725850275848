import '@fortawesome/fontawesome-svg-core/styles.css';
import '../../styles/style.css';
import '../../styles/global.css';

import React from 'react';
import Helmet from 'react-helmet';
import cx from 'classnames';
import Navigation from '../primary-navigation';
import Footer from '../footer';

const Layout = ({ className, headerClass, bodyClass, children, ...props }) => {
  bodyClass = bodyClass || 'w-full';
  return (
    <div
      className={cx(className, 'flex flex-col justify-content-between')}
      {...props}
    >
      <Helmet>
        <body className="font-sans antialiased" />
      </Helmet>

      <Navigation className={headerClass} />
      <div className={bodyClass}>{children}</div>
      <Footer />
    </div>
  );
};

export default Layout;
