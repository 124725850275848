import React, { useState } from 'react';

import { Link } from 'gatsby';

import { footerNavigation } from './footer.content';
import cx from 'classnames';

const Footer = () => {
  let successFlag = false;

  const [email, setEmail] = useState('');
  const [invalidEmail, setInvalidEmail] = useState(false);
  const [postError, setPostError] = useState(false);
  const [successfulSubmission, setSuccessfulSubmission] = useState(false);

  function handleEmailSubmission(email) {
    let validEmailFlag = validEmail(email);

    if (!validEmailFlag) {
      setInvalidEmail(true);
      setPostError(false);
      setSuccessfulSubmission(false);
      return;
    }
    setInvalidEmail(false);
    postEmailSubmission(email);
  }

  function postEmailSubmission(email) {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        data: { type: 'newsletter_subscribers', attributes: { email: email } },
      }),
    };

    fetch(
      'https://twin-developer-platform.herokuapp.com/newsletter_subscribers/',
      requestOptions
    )
      .then(async (response) => {
        // check for error response
        if (!response.ok) {
          setPostError(true);
          setSuccessfulSubmission(false);
        } else {
          setPostError(false);
          setSuccessfulSubmission(true);
        }
      })
      .catch((error) => {
        setPostError(true);
        setSuccessfulSubmission(false);
      });
  }

  /**
   * validate email
   *
   * @see {@link https://stackoverflow.com/questions/46155/whats-the-best-way-to-validate-an-email-address-in-javascript}
   */
  function validEmail(email) {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  }

  return (
    <footer className="bg-coolGray-900" aria-labelledby="footer-heading">
      <h2 id="footer-heading" className="sr-only">
        Footer
      </h2>
      <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
        <div className="md:grid md:grid-cols-3 md:gap-8">
          <div className="mt-12 md:col-span-2 md:grid md:grid-cols-2 md:gap-8 xl:mt-0 xl:col-span-2">
            <div className="grid grid-cols-2 gap-8 mb-8">
              <div>
                <ul className="mt-4 space-y-4">
                  {footerNavigation.solutions.map((item) => (
                    <li key={item.name}>
                      <Link
                        to={item.href}
                        className="text-base text-white hover:text-indigo-100"
                      >
                        {item.name}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="mt-12 md:mt-0">
                <ul className="mt-4 space-y-4">
                  {footerNavigation.legal.map((item) => (
                    <li key={item.name}>
                      <Link
                        target="_blank"
                        to={item.href}
                        className="text-base text-green-300 hover:text-green-100"
                      >
                        {item.name}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
          <div
            className={cx(
              successfulSubmission
                ? 'text-sm font-semibold text-green-300 tracking-wider uppercase mt-24 ml-4'
                : 'hidden'
            )}
          >
            Thank you!<br></br>
            Glad to have you on board!
          </div>
          <div className={cx(successfulSubmission ? 'hidden' : 'block')}>
            50
            <h3 className="text-sm font-semibold text-green-300 tracking-wider uppercase mb-4">
              want to know more?
            </h3>
            <p className="text-base text-gray-200 mb-4">
              The latest news, updates, and resources, sent direct to your
              inbox.
            </p>
            <div className="flex items-center">
              <input
                className="h-full w-full py-3 px-2 rounded-md placeholder-coolGray-700"
                placeholder="Enter your email"
                value={email}
                onChange={(event) => {
                  setInvalidEmail(false);
                  setPostError(false);
                  setSuccessfulSubmission(false);
                  setEmail(event.target.value);
                }}
              />
              <button
                type="button"
                className="btn px-4 py-2 rounded-md ml-4 text-gray-900 text-base"
                onClick={handleEmailSubmission.bind(null, email)}
              >
                Subscribe
              </button>
            </div>
            <div
              className={cx(
                invalidEmail ? '' : 'hidden',
                'text-red-600 ml-1 mt-1'
              )}
            >
              invalid email
            </div>
            <div
              className={cx(
                postError ? '' : 'hidden',
                'text-red-600 ml-1 mt-1'
              )}
            >
              submission error
            </div>
            <div
              className={cx(
                successfulSubmission ? '' : 'hidden',
                'text-white-600'
              )}
            >
              successful submission
            </div>
          </div>
        </div>
        <div className="mt-8 border-t border-white pt-8 md:flex md:items-center md:justify-between">
          <div className="flex space-x-6 md:order-2">
            {footerNavigation.social.map((item) => (
              <a
                key={item.name}
                target="_blank"
                rel="noreferrer"
                href={item.href}
                className="text-green-300 hover:text-green-200"
              >
                <span className="sr-only">{item.name}</span>
                <item.icon className="h-6 w-6" aria-hidden="true" />
              </a>
            ))}
          </div>
          <p className="mt-8 text-base text-green-300 md:mt-0 md:order-1">
            &copy; 2021 Fetch.ai. All rights reserved.
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
